.title {
	user-select: none;
	z-index: 1;
	fill: var(--text-consistent-od-01);
}

.shape {
	z-index: 0;
	fill: var(--background-button-second);
	stroke-width: 1.1766;
	transition: fill 500ms ease-out;

	.selected &,
	&:focus {
		fill: var(--background-default-sixth);
	}

	@media screen and (hover: hover) and (pointer: fine) {
		&:hover {
			fill: var(--background-default-sixth);
		}
	}
}

.link {
	&.selected,
	&:focus {
		> .shape {
			fill: var(--background-default-sixth);
		}

		> .title {
			text-decoration: underline;
			fill: var(--text-highlight-first);
		}
	}

	&:focus-visible {
		outline: none;

		> .title {
			outline: var(--outline-width) double var(--text-highlight-first);
			fill: var(--text-highlight-first);
		}
	}

	@media screen and (hover: hover) and (pointer: fine) {
		&:hover {
			> .shape {
				fill: var(--background-default-sixth);
			}

			> .title {
				text-decoration: underline;
				fill: var(--text-highlight-first);
			}
		}
	}
}
