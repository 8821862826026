@use 'spacing';
@use 'breakpoints';
@use 'br24-common';

.header {
	margin-top: spacing.$s600;
	padding: 0 spacing.$s400;
	color: var(--text-consistent-od-01);
	text-align: center;

	@media only screen and (min-width: breakpoints.$lg) {
		margin-top: 0;
	}
}

.map {
	max-width: br24-common.pixel-to-rem(400px);

	@media screen and (min-width: breakpoints.$lg) {
		max-width: unset;
		margin-bottom: 0;
	}
}

.wrapper {
	position: relative;

	overflow: hidden;
	display: flex;
	flex: 1;
	align-items: center;

	background-color: var(--background-default-third);

	@media only screen and (min-width: breakpoints.$lg) {
		justify-content: space-evenly;
	}
}

.maxHeightWrapper {
	display: flex;
	flex-direction: column;
	gap: spacing.$s1500;
	align-items: center;

	width: 100%;
	max-height: br24-common.pixel-to-rem(600px);
	margin-top: spacing.$s800;
	margin-bottom: spacing.$s1500;
	margin-inline: spacing.$s1000;
}
